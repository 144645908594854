import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/api.service';
import { AuthCase } from './authcase.model';
import { AuthResult } from './authresult.model';
import { LoginDto } from './logindto.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loading: boolean;
  authResult: AuthResult;
  passwordChanged: boolean;
  passwordError: string;
  mcp: AuthCase = AuthCase.MUST_CHANGE_PASSWORD;
  loginDto: LoginDto;
  renderMSBtn: boolean = false;

  constructor(private apiService: ApiService, private router: Router) {
    this.loading = false;
    this.passwordChanged = false;
    this.passwordError = '';
    this.loginDto = new LoginDto();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    this.passwordChanged = false;
    this.passwordError = '';
    this.loading = false;
    this.authResult = new AuthResult(false, null, null, null);
    window.location.host.includes('inteliqua')
      ? (this.renderMSBtn = true)
      : (this.renderMSBtn = false);
  }

  login() {
    let me = this;
    this.loading = true;
    this.apiService.login(this.loginDto).subscribe(
      (rsp) => {
        me.authResult = rsp;
        if (rsp._authCase === 1) {
          this.router.navigate([
            'change-password',
            {
              expired: true,
              username: this.loginDto.username,
              account: this.loginDto.account,
            },
          ]);
        }
        if (rsp._userPrincipal.mfaToken) {
          this.router.navigate([
            'mfa',
            {
              username: this.loginDto.username,
              account: this.loginDto.account,
              mfaImage: rsp._userPrincipal.mfaImage,
              mfaToken: rsp._userPrincipal.mfaToken,
            },
          ]);
        }
        this.loading = false;
      },
      (rsp) => {
        this.authResult = rsp;
        this.loading = false;
      }
    );
  }

  isLocked(): boolean {
    return (
      this.authResult != null &&
      this.authResult.authCase === AuthCase.ACCOUNT_LOCKED
    );
  }

  invalidCredentials(): boolean {
    return (
      this.authResult != null &&
      this.authResult.authCase === AuthCase.INVALID_CREDENTIALS
    );
  }

  isGenericAuthError(): boolean {
    return (
      this.authResult != null &&
      this.authResult.authCase === AuthCase.GENERIC_ERROR
    );
  }

  backToLogin() {
    this.init();
  }

  loginToQC(group) {
    this.apiService
      .loginToQC(
        `${group.url}/idp-api/public/public/auth/token`,
        this.authResult.jwtToken
      )
      .subscribe((val) => {
        window.location.href = `${group.url}/liquid`;
      });
  }

  changePassword() {
    this.router.navigate([
      'change-password',
      {
        username: this.loginDto.username,
        account: this.loginDto.account,
      },
    ]);
  }

  MicrosoftLogin() {
    this.apiService.loginWithMicrosoft().subscribe((val) => {
      window.location.href = val.payload.msRedirectUrl;
    });
  }
}

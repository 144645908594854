import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutofocusDirective } from './autofocus.directive';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ApiService } from './common/api.service';
import { HttpResponseInterceptor } from './common/http.interceptor';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LockedComponent } from './locked/locked.component';
import { LoginComponent } from './login/login.component';
import { MfaComponent } from './mfa/mfa.component';
import { MsLoginComponent } from './ms-login/ms-login.component';
import { ErrorPageComponent } from './error-page/error-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotpasswordComponent,
    AutofocusDirective,
    ChangepasswordComponent,
    LockedComponent,
    MfaComponent,
    MsLoginComponent,
    ErrorPageComponent,
  ],
  imports: [BrowserModule, HttpClientModule, FormsModule, AppRoutingModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptor,
      multi: true,
    },
    ApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

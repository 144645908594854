import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../common/api.service';

@Component({
  selector: 'app-ms-login',
  templateUrl: './ms-login.component.html',
  styleUrls: ['./ms-login.component.scss'],
})
export class MsLoginComponent implements OnInit {
  constructor(private apiService: ApiService, private router: Router) {}
  groups: string[] = [];
  jwtToken: string;
  loading: boolean = true;

  ngOnInit() {
    let params = window.location.search.split(/=(.*)/)[1];
    this.apiService.loginWithMicrosoftGetJWT(params).subscribe(
      (rsp) => {
        this.groups = rsp.body.payload.groups ? rsp.body.payload.groups : [];
        this.jwtToken = rsp.headers.get('x-jwt-token');
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.router.navigate(['error']);
      }
    );
  }

  loginToQC(group) {
    this.apiService
      .loginToQC(`${group.url}/idp-api/public/public/auth/token`, this.jwtToken)
      .subscribe(() => {
        window.location.href = `${group.url}/liquid`;
        //         this.router.navigate(['login']);
      });
  }

  backToLogin() {
    this.router.navigate(['login']);
  }
}

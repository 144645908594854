import {AuthCase} from './authcase.model';
import {UserPrincipal} from '../common/userprincipal.model';

export class AuthResult {
  private _authenticated: boolean;
  private _authCase: AuthCase;
  private _jwtToken: string;
  private _userPrincipal: UserPrincipal;


  constructor(authenticated: boolean, authCase: AuthCase, jwtToken: string, userPrincipal: UserPrincipal) {
    this._authenticated = authenticated;
    this._authCase = authCase;
    this._jwtToken = jwtToken;
    this._userPrincipal = userPrincipal;
  }

  get authenticated(): boolean {
    return this._authenticated;
  }

  set authenticated(value: boolean) {
    this._authenticated = value;
  }

  get authCase(): AuthCase {
    return this._authCase;
  }

  set authCase(value: AuthCase) {
    this._authCase = value;
  }

  get jwtToken(): string {
    return this._jwtToken;
  }

  set jwtToken(value: string) {
    this._jwtToken = value;
  }

  get userPrincipal(): UserPrincipal {
    return this._userPrincipal;
  }

  set userPrincipal(value: UserPrincipal) {
    this._userPrincipal = value;
  }
}

import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthCase } from '../login/authcase.model';
import { AuthResult } from '../login/authresult.model';
import { ChangePasswordDto } from '../login/changepassworddto.model';
import { LoginDto } from '../login/logindto.model';
import { GenericResponse, GenericResponseMFA } from './genericresponse.model';
import { UserPrincipal } from './userprincipal.model';

@Injectable()
export class ApiService {
  private username: string;
  private account: string;
  constructor(private http: HttpClient) {}

  getUsername(): string {
    return this.username;
  }

  getAccount(): string {
    return this.account;
  }

  changePassword(
    username: string,
    account: string,
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    const passwordDto = new ChangePasswordDto(
      username,
      account,
      oldPassword,
      newPassword
    );
    return this.http
      .post<GenericResponse<any>>(
        `${environment.apiEndpoint}/account/password`,
        passwordDto,
        {
          observe: 'response',
        }
      )
      .toPromise()
      .then(
        (rsp) => {
          const jwtToken = rsp.headers.get('X-JWT-TOKEN');
          rsp['token'] = jwtToken;
          return Promise.resolve(new GenericResponse(true, rsp));
        },
        (rsp) => {
          return Promise.reject(rsp.error.payload.message);
        }
      );
  }

  sendValidationCode(channel: string, method): Promise<GenericResponse<any>> {
    return this.http
      .get<GenericResponse<any>>(
        `${environment.apiEndpoint}/account/validation-code?${method}=${channel}`
      )
      .toPromise()
      .then(
        (rsp) => {
          if (rsp.success) {
            return Promise.resolve(new GenericResponse(true, 'OK'));
          } else {
            return Promise.reject(new GenericResponse(false, rsp.payload));
          }
        },
        (rsp) => {
          return Promise.reject(new GenericResponse(false, rsp.error.payload));
        }
      );
  }

  validateCode(
    val: string,
    code: string,
    method
  ): Promise<GenericResponse<any>> {
    const data = {
      m: method,
      v: val,
      c: code,
    };

    return this.http
      .post<GenericResponse<any>>(
        `${environment.apiEndpoint}/account/validate-code`,
        data
      )
      .toPromise()
      .then(
        (rsp) => {
          if (rsp.success) {
            return Promise.resolve(new GenericResponse(true, 'OK'));
          } else {
            return Promise.reject(new GenericResponse(false, rsp.payload));
          }
        },
        (rsp) => {
          return Promise.reject(new GenericResponse(false, rsp.error.payload));
        }
      );
  }

  resetPassword(
    mobile: string,
    code: string,
    password: string,
    email: string
  ): Promise<GenericResponse<any>> {
    const changePasswordDto = {
      code: code,
      mobile: mobile,
      email: email,
      password: password,
    };

    return this.http
      .post<GenericResponse<any>>(
        `${environment.apiEndpoint}/account/password-reset`,
        changePasswordDto
      )
      .toPromise()
      .then(
        (rsp) => {
          if (rsp.success) {
            return Promise.resolve(new GenericResponse(true, rsp.payload));
          } else {
            return Promise.resolve(new GenericResponse(false, rsp.payload));
          }
        },
        (rsp) => {
          return Promise.resolve(new GenericResponse(false, rsp.error.payload));
        }
      );
  }

  handleLoginErrors(error: HttpErrorResponse): AuthResult {
    let authResultCase;
    switch (error.status) {
      case 403:
        authResultCase = AuthCase.ACCOUNT_LOCKED;
        break;
      case 401:
        authResultCase = AuthCase.INVALID_CREDENTIALS;
        break;
      case 502:
        authResultCase = AuthCase.GENERIC_ERROR;
        break;
      default:
        break;
    }
    return new AuthResult(false, authResultCase, null, null);
  }

  loginToQC(url, token: string): Observable<any> {
    return this.http.post(url, token, { withCredentials: true });
  }

  login(loginDto: LoginDto): Observable<any> {
    return this.http
      .post<GenericResponse<UserPrincipal>>(
        `${environment.apiEndpoint}/auth/login`,
        loginDto,
        { observe: 'response' }
      )
      .pipe(
        map((rsp) => {
          const jwtToken = rsp.headers.get('X-JWT-TOKEN');
          if (rsp.status === 202) {
            return new AuthResult(
              true,
              AuthCase.MUST_CHANGE_PASSWORD,
              null,
              null
            );
          } else {
            return new AuthResult(
              true,
              AuthCase.VALID_CREDENTIALS,
              jwtToken,
              rsp.body.payload
            );
          }
        }),
        catchError((err: HttpErrorResponse): any => {
          return observableThrowError(this.handleLoginErrors(err));
        })
      );
  }

  validateTOTPCode(
    username: string,
    account: string,
    totp: string,
    mfaToken: string
  ): Promise<GenericResponseMFA<any>> {
    const data = {
      username,
      account,
      totp,
      mfaToken,
    };
    return this.http
      .post<GenericResponseMFA<any>>(
        `${environment.apiEndpoint}/auth/mfa/validate`,
        data,
        { observe: 'response' }
      )
      .toPromise()
      .then(
        (rsp) => {
          const jwtToken = rsp.headers.get('X-JWT-TOKEN');
          if (rsp) {
            return Promise.resolve(
              new GenericResponseMFA(true, rsp.body.payload, jwtToken)
            );
          } else {
            return Promise.reject(new GenericResponse(false, rsp));
          }
        },
        (rsp) => {
          return Promise.reject(new GenericResponse(false, rsp.error.payload));
        }
      )
      .catch((e) => {
        return e;
      });
  }

  loginWithMicrosoft(): Observable<any> {
    return this.http.get<any>(`${environment.apiEndpoint}/ms/auth`);
  }

  loginWithMicrosoftGetJWT(params): Observable<any> {
    return this.http.get<any>(
      `${environment.apiEndpoint}/ms/auth/jwt?code=${params}`,
      { observe: 'response' }
    );
  }
}

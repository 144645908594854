import { Component, OnInit } from '@angular/core';
import {ApiService} from '../common/api.service';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
    
    step: number;
    mobile: string;
    email: string;
    loading: boolean;
    code: string;
    errorString: string;
    password: string;
    passwordRepeat: string;
    validationOption: string;
    channel: string;
    method: string;
    doPasswordsMatch: boolean;
    
    constructor(private apiService: ApiService) {
        this.init();
        this.validationOption = 'email';
        this.doPasswordsMatch = true;
    }
    
    init() {
        this.step = 1;
        this.errorString = '';
        this.loading = false;
        this.code = '';
        this.mobile = '';
        this.email = '';
    }
    
    ngOnInit() {
    }
    
    sendValidationCode() {
        this.loading = true;
        if (this.mobile) {
            this.channel = encodeURIComponent(this.mobile);
            this.method = 'm';
        } else {
            this.channel = this.email;
            this.method = 'e';
        }

        this.apiService.sendValidationCode(this.channel, this.method).then(rsp => {
            if (rsp.success) {
                // Proceed to step 2
                this.step = 2;
                this.errorString = '';
            } else {
                this.errorString = rsp.payload;
            }
            
            this.loading = false;
        }, rsp => {
            this.errorString = 'We could not match your details. Please try another method.';
            this.loading = false;
        });
    }
    
    validateCode() {
        this.loading = true;
        
        if (this.mobile) {
            this.channel = encodeURIComponent(this.mobile);
            this.method = 'm';
        } else {
            this.channel = this.email;
            this.method = 'e';
        }
        this.apiService.validateCode(this.channel, this.code, this.method).then(rsp => {
            if (rsp.success) {
                this.step = 3;
                this.errorString = '';
            } else {
                this.errorString = rsp.payload;
            }
            this.loading = false;
        }, rsp => {
            this.errorString = rsp.payload;
            this.loading = false;
        });
    }
    
    changePassword() {
        this.loading = true;
        
        this.apiService.resetPassword(this.mobile, this.code, this.password, this.email).then(rsp => {
            if (rsp.success) {
                this.step = 4;
                this.errorString = '';
            } else {
                this.errorString = rsp.payload;
            }
            this.loading = false;
        }, rsp => {
            this.errorString = rsp.payload;
        });
    }
    
    
    toggleAuthenticationOption(option) {
        if (option === 'email') {
            this.mobile = '';
        } else {
            this.email = '';
        }
    }
    
    matchPasswords() {
        this.doPasswordsMatch = this.password === this.passwordRepeat;
    }
    
}

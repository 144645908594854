import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { LockedComponent } from './locked/locked.component';
import { LoginComponent } from './login/login.component';
import { MfaComponent } from './mfa/mfa.component';
import { MsLoginComponent } from './ms-login/ms-login.component';
import { ErrorPageComponent } from './error-page/error-page.component';

export const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotpasswordComponent },
  { path: 'change-password', component: ChangepasswordComponent },
  { path: 'account-locked', component: LockedComponent },
  { path: 'mfa', component: MfaComponent },
  { path: 'ms-login', component: MsLoginComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent
} from '@angular/common/http';
 
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
 
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
    
    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const request = req.clone();
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    const response = <HttpResponse<any>>event;
                    if (response.status === 200) {
                        this.handleAccountLockout(response);
                    }
                }
            })
        );
    }

    handleAccountLockout = (response: HttpResponse<any>) => {
        const body = response.body;
        try {
            if (!body.success && body.payload.REASON === 'ACCOUNT_LOCKED') {
                this.router.navigate(['/account-locked']);
            }
        } catch (error) {
            // do nothing
            console.error(error);
        }
    }
}
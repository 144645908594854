import {Component, OnInit} from '@angular/core';
import {ApiService} from '../common/api.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  username: string;
  password: string;
  account: string;
  urlDefined = false;
  password_new1: string;
  password_new2: string;
  loading: boolean;
  passwordChanged: boolean;
  passwordError = '';
  passwordExpired: string;
  doPasswordsMatch: boolean;
  token: string;
  hasChangedPassword: boolean;

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) {
    this.loading = false;
    this.doPasswordsMatch = true;
    this.hasChangedPassword = false;
  }

  ngOnInit() {
    this.route.params.subscribe( params => {
      this.passwordExpired = params.expired;
      this.token = params.token;
      this.account = params.account;
      if (params.username !== undefined) {
        this.username = params.username;
        this.urlDefined = true;
      }
    });

  }

  changePassword() {
    this.loading = true;
    this.passwordError = '';
    this.apiService.changePassword(this.username, this.account, this.password, this.password_new1).then(rsp => {
      if (rsp.success) {
        this.loading = false;
        this.token = rsp._payload.token;
        this.hasChangedPassword = true;
      }
    }, rsp => {
      this.loading = false;
      this.passwordError = rsp;
    });
  }

  backToLogin() {
    this.router.navigate(['login']);
  }


  matchPasswords() {
    this.doPasswordsMatch = this.password_new1 === this.password_new2;
  }
}
